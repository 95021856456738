/**
 * Author:    Ravi
 * Created:   11.11.2024
 * 
 * (c) Copyright by Blub Corp. Develop by Artcode
 **/

body {
    /* background: rgb(190, 222, 79); */
    /* background: linear-gradient(180deg, rgba(190, 222, 79, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    color: #727272;
}

.main-page {
    min-height: 60vh;
}

.section-pending {
    padding: 60px 0;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4 {
    color: #121212;
}

/*
 * Header
 */
.header-main {}

.header-main .row {
    align-items: center;
}

.header-main .container {}

.header-logo a {
    display: inline-flex;
    align-items: center;
    margin-top: -50px;
    background: #fff;
    justify-content: center;
    /* padding: 43px; */
    border-radius: 10px;
    gap: 15px;
}

.header-logo img {
    height: 130px;
}

.header-logo img:nth-child(2) {
    height: 100px;
}

.header-menu .navbar-collapse {
    justify-content: flex-end;
}

.header-menu .nav-link {
    font-size: 18px;
    color: #212121;
}

.header-menu .nav-link.active {
    font-weight: bold;
}

.top-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.top-header ul img {
    height: 18px;
}

.top-header {
    background: rgb(190, 222, 79);
    /* background: linear-gradient(180deg, rgba(190, 222, 79, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    padding: 10px 0;
}

.menu-header {
    padding: 15px 0;
}

.menu-header .jbtn {
    background: #363636;
    border-radius: 10px;
    padding: 10px 20px !important;
    color: #BEDE4F;
    font-weight: 700;
}

.menu-header .navbar-nav {
    display: flex;
    align-items: center;
    gap: 20px;
}


/*
* Footer
*/
.main-footer {
    background-color: #121212;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px 0 20px;
    color: #fff;
    font-weight: 400;
}

.main-footer h3 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
}

.main-footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-footer ul a {
    text-decoration: none;
    color: #fff;
}

.main-footer ul li {
    margin-bottom: 10px;
}

.footer-contect li label {
    display: block;
    font-size: 14px;
    font-weight: 200;
}

.footer-contect li a {
    font-size: 20px;
}

.main-footer .logos {
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 30px 0 50px;
}

.main-footer .logos img {
    height: 100px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.copyright {
    background-color: #050505;
    padding: 20px;
    margin-top: 40px;
    border-radius: 10px;
}

.copyright a {
    text-decoration: none;
    color: #fff;
}

.copyright .col-md-6:nth-child(2) {
    text-align: right;
}

.footer-col-1 .box {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-col-1 .box img {
    height: 50px;
    border-radius: 10px;
}

/*
* Home 
*/
.home-banner {
    padding-top: 0px;
}

.home-banner img {
    max-width: 100%;
}

.home-intro .title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    color: #121212;
}

.home-intro .col-md-8 .row {
    align-items: center;
    margin-bottom: 40px;
}

.home-intro .col-md-8 p {
    margin-bottom: 0px;
}

.home-intro p {
    text-align: justify;
}


.home-intro .right-box {
    background-color: #f5f5f5;
    padding: 30px;
    /* height: 100%; */
    border-radius: 10px;
}

.home-intro video {
    width: 100%;
    height: 100%;
}

.user-box {
    text-align: center;
    margin: 0 0 25px 0;
}

.user-box img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 10px;
}

.user-box h3 {
    font-size: 18px;
    margin: 0;
}

.user-box p {
    text-align: center;
}

.research-box {
    text-align: center;
    background-color: #e1e1e1;
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
}

.research-box h3 {
    font-size: 20px;
    font-weight: 700;
}

.research-box .btn {
    background: #363636;
    border-radius: 10px;
    padding: 10px 20px;
    color: #BEDE4F;
    font-weight: 700;
}

.research-box p {
    text-align: center;
}

.home-intro-1 img {
    width: 100%;
}


/*
* Abhiyaan
*/

.ev-ab-1-1 {
    text-align: center;
}

.ev-ab-1-1 .title {
    font-size: 32px;
    font-weight: bold;
}

.ev-ab-1-1 {
    margin-bottom: 60px;
}

.ev-ab-1-2 .col-md-4 {
    margin-bottom: 30px;
}

.ev-ab-1-2 .box {
    text-align: center;
    background: #f6f6f6;
    /* background: linear-gradient(123deg, rgba(190, 222, 79, 1) 0%, rgba(125, 141, 67, 1) 28%, rgba(54, 54, 54, 1) 85%); */
    height: 100%;
    border-radius: 15px;
    padding: 20px;
    /* box-shadow: 0px 0px 15px -7px #777; */
}

.ev-ab-1-2 h3 {
    font-size: 20px;
    font-weight: 900;
}

.ev-ab-1-2 img {
    height: 150px;
    margin-bottom: 30px;
}

.ev-ab-1-1-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.ev-ab-1-1-2 img {
    width: 200px;
}

/*
* FAQ
*/

.faq-1 .row {
    justify-content: center;
}

.faq-box {
    margin-bottom: 20px;
}

.faq-box .accordion-button {
    outline: none !important;
    box-shadow: none !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
}

.faq-box .accordion-item {
    padding: 15px;
}

.faq-1-1 {
    text-align: center;
    margin-bottom: 30px;
}

.faq-1-1 .title {
    font-size: 32px;
    font-weight: bold;
}

/*
* volunteers
*/

.vl-1 {
    text-align: center;
}

.vl-2 .row,
.vl-1 .row {
    justify-content: center;
}

.vl-1 .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.vl-2 {
    text-align: center;
    background: #f6f6f6;
}

.vl-2 ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 30px;
}

.vl-2 ul li {
    cursor: pointer;
}

.vl-2 ul li a {
    display: block;
    border: 2px solid #363636;
    color: #363636;
    font-weight: 700;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
}

.vl-2 ul li a:hover {
    background-color: #363636;
    color: #BEDE4F;
    transition-duration: 0.6s;
}

.vl-3 .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 20px;
}

.vl-3 h3 {
    font-size: 18px;
    font-weight: 900;
    text-decoration: underline;
    margin-top: 40px;
}

.vl-3 p {
    text-align: justify;
}

.vl-3 .col-md-6 {
    padding: 0px 30px;
}

.vl-3 li {
    text-align: justify;
    margin: 10px 0;
}

/*
* Sience
*/
.si-2 .title,
.si-1 .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 20px;
}

.si-1 h3 {
    font-size: 18px;
    font-weight: 900;
    text-decoration: underline;
    margin-top: 40px;
}

.si-1 li {
    text-align: justify;
    margin: 10px 0;
}

.si-1 .col-md-6 {
    padding: 0px 30px;
}

.si-1 li a {
    color: #121212;
    margin-left: 10px;
}

.si-2 {
    /* background-color: #f6f6f6; */
    padding-bottom: 80px;
    margin-bottom: -20px;
}

.si-2-2 {
    justify-content: center;
}

.si-2-2 .box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    flex: 1;
    flex-direction: column;
}

.si-2-2 a {
    display: block;
    border: 2px solid #363636;
    color: #363636;
    font-weight: 700;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.si-2-2 a svg {
    font-size: 30px;
}

.si-2-2 a:hover {
    background-color: #363636;
    color: #BEDE4F;
    transition-duration: 0.6s;
}

.tcnt-2 {
    text-align: left;
}

.tcnt-2 h3 {
    font-size: 18px;
    text-decoration: underline;
    margin-top: 40px;
}

.tcnt-3 {
    justify-content: center;
    text-align: center;
}

.tcnt-3 h3 {
    font-size: 18px;
    text-decoration: underline;
    margin-bottom: 15px;
}



@media (max-width:767px) {
    .main-footer .logos {
        flex-wrap: wrap;
        gap: 10px;
    }

    .main-footer .row:nth-child(2) [class^="col-md-"] {
        margin-bottom: 20px;
    }

    .copyright .col-md-6:nth-child(2),
    .copyright {
        text-align: center;
    }

    .header-logo img {
        height: 100px;
    }

    .header-logo img:nth-child(2) {
        height: 80px;
    }

    .menu-header .navbar {
        position: unset;
    }

    .menu-header .navbar-collapse {
        position: absolute;
        top: 133px;
        left: 0;
        background: #fff;
        width: 100%;
        padding: 20px 0;
    }

    .navbar-toggler {
        background: #bede4f;
        border-color: #bede4f;
    }

    .home-banner img {
        height: 26vh;
        object-fit: cover;
        object-position: center;
    }

    .home-intro .col-md-9 .row:nth-child(1) .col-md-6:nth-child(2) {
        order: 0;
    }

    .home-intro .col-md-9 .row:nth-child(1) .col-md-6:nth-child(1) {
        order: 1;
        margin-top: 20px;
    }

    .home-intro .col-md-9 .row:nth-child(2) .col-md-6:nth-child(2) {
        margin-top: 20px;
    }

    .home-intro .col-md-9 .row:nth-child(3) .col-md-6:nth-child(2) {
        order: 0;
    }

    .home-intro .col-md-9 .row:nth-child(3) .col-md-6:nth-child(1) {
        order: 1;
        margin-top: 20px;
    }

    .faq-box .accordion-button {
        /* padding: 0; */
        display: flex;
        gap: 10px;
    }
}